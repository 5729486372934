'use client';

import { useMemo } from 'react';
import {
  ClientProvider as OpenFeatureClientProvider,
  type OpenFeatureEvaluationContext,
} from '../../../openfeature';
import { createConfidenceProvider } from './provider';

export function ConfidenceClientProvider({
  ready,
  clientToken,
  evaluationContext,
  children,
}: {
  ready: boolean;
  clientToken: string | undefined;
  evaluationContext: OpenFeatureEvaluationContext;
  children: React.ReactNode;
}) {
  const provider = useMemo(() => {
    if (!ready || !clientToken) {
      return null;
    }

    return createConfidenceProvider(clientToken);
  }, [clientToken, ready]);

  return (
    <OpenFeatureClientProvider
      evaluationContext={evaluationContext}
      provider={provider}
    >
      {children}
    </OpenFeatureClientProvider>
  );
}
