import type { EvaluationContext } from '@openfeature/core';

export interface DeviceEvaluationContext {
  isMobile: boolean;
}

export function getDeviceEvaluationContext(
  userAgent: string,
): DeviceEvaluationContext & EvaluationContext {
  return {
    isMobile: isHandheld(userAgent),
  };
}

function isHandheld(userAgent: string): boolean {
  return Boolean(
    userAgent.match(/Android/i) ||
      userAgent.match(/webOS/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPod/i) ||
      userAgent.match(/iPad/i) ||
      userAgent.match(/BlackBerry/),
  );
}
