import type { EvaluationContext } from '@openfeature/react-sdk';

interface VisitorEvaluationContextParams {
  visitorId: string | undefined;
}

export interface VisitorEvaluationContext {
  visitor_id: string;
}

export function getVisitorEvaluationContext({
  visitorId,
}: VisitorEvaluationContextParams): VisitorEvaluationContext &
  EvaluationContext {
  return {
    visitor_id: visitorId || '',
  };
}
