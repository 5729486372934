'use client';

// This import must go first
import 'src/http-setup';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { designSystemConfig } from '@mentimeter/ragnar-dsc';
import { themes } from '@mentimeter/ragnar-colors';
import { ThemeProvider } from '@mentimeter/ragnar-react';
import { DeviceProvider } from '@mentimeter/ragnar-device';
import { stripAllNonNumericChars } from '@mentimeter/ragnar-utils';
import { isAuthorizedToInteractWithDevelopmentTools } from '@mentimeter/user';
import { LocalStorage } from '@mentimeter/storage';
import Cookies from '@mentimeter/cookies';
import { USER_LOCALE_STORAGE_KEY } from '@mentimeter/i18n/constants';
import { userExperiments, visitorExperiments } from 'src/split-experiments';
import { SplitIOSetup } from 'src/split-setup';
import { ConfidenceProvider } from 'app/[lang]/ConfidenceProvider';
import { FelaProvider } from './FelaProvider';
import { AppStateProvider } from './AppStateProvider';

const ENABLE_INTERCOM = process.env.NEXT_PUBLIC_ENABLE_INTERCOM;
const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID as string;

const DynamicIntercomLoader = dynamic(
  () => import('src/components/IntercomLoader'),
  {
    ssr: false,
  },
);

const DevelopmentToolsSetup = dynamic(
  () =>
    import('@mentimeter/development-tools/setup').then(
      (mod) => mod.DevelopmentToolsSetup,
    ),
  {
    ssr: false,
  },
);

const DSC = {
  ...designSystemConfig,
  ...themes.light,
  //           0   1   2   3   4   5   6   7   8   9
  fontSizes: [12, 14, 16, 20, 24, 32, 36, 38, 48, 64],
  lineHeights: {
    none: 1,
    body: 1.4,
    heading: 1,
  },
};

const breakpoints = DSC.breakpoints.map<number>(stripAllNonNumericChars);

const CONFIDENCE_CLIENT_TOKEN = process.env.NEXT_PUBLIC_CONFIDENCE_CLIENT_TOKEN;

export function Providers({
  lang,
  newlyGeneratedServerToken,
  country,
  userAgent,
  children,
}: {
  lang: string;
  newlyGeneratedServerToken: string;
  country: string | null;
  userAgent: string | null;
  children: React.ReactNode;
}) {
  useMigrateLocalStorageLocaleToCookie();

  // TODO: This is to replicate a request to /split-data
  // and setting up the proper data in LocalStorage (country, prefferedLanguage).
  // This functionality will be needed in other apps too once they are more server-sided.
  // Think about a good way to place this to be shared (might be a part of SplitIO package).
  if (LocalStorage.getItem('data-split-country') === null) {
    LocalStorage.setItem({
      key: 'data-split-country',
      value: country ?? 'none',
      type: 'functional',
    });
  }

  if (LocalStorage.getItem('data-split-preferredLanguage') === null) {
    LocalStorage.setItem({
      key: 'data-split-preferredLanguage',
      value: lang,
      type: 'functional',
    });
  }

  return (
    <ThemeProvider theme={DSC}>
      <DeviceProvider breakpoints={breakpoints}>
        <FelaProvider>
          <AppStateProvider
            newlyGeneratedServerToken={newlyGeneratedServerToken}
          >
            <ConfidenceProvider
              userAgent={userAgent ?? ''}
              clientToken={CONFIDENCE_CLIENT_TOKEN}
            >
              <SplitIOSetup>
                <DynamicIntercomLoader
                  enableIntercom={ENABLE_INTERCOM === 'true'}
                  intercomAppid={INTERCOM_APP_ID}
                />
                <DevelopmentToolsSetup
                  experiments={{
                    ...visitorExperiments,
                    ...userExperiments,
                  }}
                  isAuthorizedToInteractWithDevelopmentTools={isAuthorizedToInteractWithDevelopmentTools()}
                >
                  {children}
                </DevelopmentToolsSetup>
              </SplitIOSetup>
            </ConfidenceProvider>
          </AppStateProvider>
        </FelaProvider>
      </DeviceProvider>
    </ThemeProvider>
  );
}

// When we removed the use of locale in LocalStorage this is not needed.
function useMigrateLocalStorageLocaleToCookie() {
  useEffect(() => {
    const localStorageLocale = LocalStorage.getItem(USER_LOCALE_STORAGE_KEY);
    const cookieLocale = Cookies.get(USER_LOCALE_STORAGE_KEY);
    if (localStorageLocale && !cookieLocale) {
      Cookies.set({
        type: 'functional',
        name: USER_LOCALE_STORAGE_KEY,
        value: localStorageLocale,
        options: {
          expires: 365,
          secure: true,
          sameSite: 'lax',
        },
      });
      window.location.reload();
    }
  }, []);
}
