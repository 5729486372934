import type { EvaluationContext } from '@openfeature/react-sdk';

interface EnvironmentEvaluationContextParams {
  environment: string | undefined;
}

export interface EnvironmentEvaluationContext {
  environment: string;
}

export function getEnvironmentEvaluationContext({
  environment = 'unknown',
}: EnvironmentEvaluationContextParams): EnvironmentEvaluationContext &
  EvaluationContext {
  return {
    environment,
  };
}
