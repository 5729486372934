'use client';

import React from 'react';
import {
  OpenFeature,
  OpenFeatureProvider,
  OpenFeatureTestProvider as OpenFeatureDummyProvider,
  type Provider,
  type EvaluationContext,
} from '@openfeature/react-sdk';
import { useOnce } from '@mentimeter/react-hooks';
import type { OpenFeatureEvaluationContext } from '../types';

const PROVIDER_DOMAIN = 'EXPERIMENTATION_PROVIDER';

export function ClientProvider({
  provider,
  evaluationContext,
  children,
}: {
  provider: Provider | null;
  evaluationContext: OpenFeatureEvaluationContext;
  children: React.ReactNode;
}) {
  useOnce(!!provider, async () => {
    await OpenFeature.setContext(
      // TODO: Fix this type cast
      evaluationContext as unknown as EvaluationContext,
    );

    OpenFeature.setProvider(PROVIDER_DOMAIN, provider!);
  });

  if (!provider) {
    return <OpenFeatureDummyProvider>{children}</OpenFeatureDummyProvider>;
  }

  return (
    <OpenFeatureProvider domain={PROVIDER_DOMAIN}>
      {children}
    </OpenFeatureProvider>
  );
}
