import { ConfidenceClientProvider } from '@mentimeter/flags/confidence/client';
import {
  getDeviceEvaluationContext,
  getEnvironmentEvaluationContext,
  getUserEvaluationContext,
  getVisitorEvaluationContext,
} from '@mentimeter/flags/openfeature/server';
import { parseVisitorKeyWellEnough } from '@mentimeter/splitio';
import { useAppState } from 'src/appState';

export function ConfidenceProvider({
  children,
  userAgent,
  clientToken,
}: {
  children: React.ReactNode;
  userAgent: string;
  clientToken: string | undefined;
}) {
  const { user, visitorToken, userFinishedLoading } = useAppState();
  const visitorId = parseVisitorKeyWellEnough(visitorToken ?? '');
  const userEvaluationContext = user
    ? getUserEvaluationContext({
        userId: user.id,
        userRegion: user.region,
      })
    : {};

  return (
    <ConfidenceClientProvider
      clientToken={clientToken}
      ready={userFinishedLoading}
      evaluationContext={{
        ...userEvaluationContext,
        ...getVisitorEvaluationContext({
          visitorId: visitorId ?? undefined,
        }),
        ...getDeviceEvaluationContext(userAgent ?? ''),
        ...getEnvironmentEvaluationContext({
          environment: process.env.NEXT_PUBLIC_ENVIRONMENT_NAME,
        }),
      }}
    >
      {children}
    </ConfidenceClientProvider>
  );
}
