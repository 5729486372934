import type { EvaluationContext } from '@openfeature/react-sdk';

const DEFAULT_REGION = 'eu';
type Region = 'eu' | 'us';

interface UserEvaluationContextParams {
  userId: number;
  userRegion: Region | undefined;
}

export interface UserEvaluationContext {
  user_id: string;
}

export function getUserEvaluationContext({
  userId,
  userRegion = 'eu',
}: UserEvaluationContextParams): UserEvaluationContext & EvaluationContext {
  return {
    user_id: regionalizeUserId(userId, userRegion),
  };
}

function regionalizeUserId(
  userId: number,
  region: Region | undefined = DEFAULT_REGION,
) {
  return region === 'us' ? `us${userId}` : String(userId);
}
