import { createConfidenceWebProvider } from '@spotify-confidence/openfeature-web-provider';

const RESOLVE_TIMEOUT_SECONDS = 1_000;

export function createConfidenceProvider(clientSecret: string) {
  return createConfidenceWebProvider({
    clientSecret,
    timeout: RESOLVE_TIMEOUT_SECONDS,
  });
}
